import { HttpClient } from '@angular/common/http';
//import { Platform , AlertController } from 'ionic-angular';
import { Injectable } from '@angular/core';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class WsService {
	private api_url:string = 'https://webapp-medicina.herokuapp.com/api/';

	constructor(
		public http: HttpClient,
		private session:SessionService,
	){ 
		//if(this.platform.is('cordova')) this.api_url = 'https://appbogados.com.ar/manager/api/';
	}

	private getHash(){
		return 'ssssss';
	}

	public login(data:any)
	{
		return new Promise((resolve, reject) => {
			this.http.post(this.api_url + 'login', data,
			{
				headers: { 'Content-Type': 'application/json' }
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}

	public retablecer(email:any)
	{
		return new Promise((resolve, reject) => {
			this.http.post(this.api_url + 'password_reset', email,
			{
				headers: { 'Content-Type': 'application/json' }
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}

	private getToken(){
		return this.session.get('api_token');
	}

	public patient_type()
	{
		return new Promise((resolve, reject) => {
			this.http.get(this.api_url + 'patient_type',
			{
				headers: { 
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+this.getToken()
				}
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}

	public consultar_elegibilidad(data:any)
	{
		return new Promise((resolve, reject) => {
			this.http.post(this.api_url + 'eligibility',data,
			{
				headers: { 
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+this.getToken()
				}
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}

	public specialities()
	{
		return new Promise((resolve, reject) => {
			this.http.get(this.api_url + 'specialities',
			{
				headers: { 
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+this.getToken()
				}
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}
	public doctor_types()
	{
		return new Promise((resolve, reject) => {
			this.http.get(this.api_url + 'doctor_types',
			{
				headers: { 
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+this.getToken()
				}
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}
	public benefit()
	{
		return new Promise((resolve, reject) => {
			this.http.get(this.api_url + 'benefit',
			{
				headers: { 
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+this.getToken()
				}
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}
	public province()
	{
		return new Promise((resolve, reject) => {
			this.http.get(this.api_url + 'province',
			{
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+this.getToken()
				}
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}

	public authorization(data:any)
	{
		return new Promise((resolve, reject) => {
			this.http.post(this.api_url + 'authorization',data,
			{
				headers: { 
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+this.getToken()
				}
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}

	public authorization_validate(data:any)
	{
		return new Promise((resolve, reject) => {
			this.http.post(this.api_url + 'authorization/validate',data,
			{
				headers: { 
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+this.getToken()
				}
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}

	public authorization_anulation(data:any)
	{
		return new Promise((resolve, reject) => {
			this.http.post(this.api_url + 'authorization/anulation',data,
			{
				headers: { 
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+this.getToken()
				}
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}

	public consulta_totales(data:any)
	{
		return new Promise((resolve, reject) => {
			this.http.post(this.api_url + 'query/total',data,
			{
				headers: { 
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+this.getToken()
				}
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}

	public consulta_totales_valorizada(data:any)
	{
		return new Promise((resolve, reject) => {
			this.http.post(this.api_url + 'query/total_valued',data,
			{
				headers: { 
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+this.getToken()
				}
			})
			.subscribe(
				(res:any) => {
					resolve(res);
				},
				(error) => {
					reject({'success':false});
				}
			);
		});
	}
}