import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
	
	data:any;
	sessionName:string='SessionData';

	constructor(
		private storage:Storage
	){
		this.data = {};
	}

	start(){
		return new Promise((resolve, reject) => {
			this.storage.get(this.sessionName).then((val) => {
				this.data = (val == null || val == undefined) ? {} : val;
				resolve(this.data);
			});
		});
	}

	set(key=null,val=null){
		if(key == null) return;
		if(typeof key === "object"){
			Object.assign(this.data,key);
		}else{
			this.data[key] = val;
		}
		this.save();
	}

	save(){
		this.storage.set(this.sessionName,this.data);
	}

	get(key=null){
		if(key == null) return this.data;
		if(this.data[key] == undefined) return null;
		return this.data[key];
	}

	check(key,val){
		key=this.get(key);
		if(!key) return false;
		return (key == val) ? true : false;
	}

	clean(){
		this.data = {};
		this.save();
	}
}