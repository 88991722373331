import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'splash', loadChildren: './splash/splash.module#SplashPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'restablecer', loadChildren: './restablecer/restablecer.module#RestablecerPageModule' },
  { path: 'restablecer-respuesta', loadChildren: './restablecer-respuesta/restablecer-respuesta.module#RestablecerRespuestaPageModule' },
  { path: 'consultar-elegibilidad', loadChildren: './consultar-elegibilidad/consultar-elegibilidad.module#ConsultarElegibilidadPageModule' },
  { path: 'solicitar-practica', loadChildren: './solicitar-practica/solicitar-practica.module#SolicitarPracticaPageModule' },
  { path: 'anular-notificacion', loadChildren: './anular-notificacion/anular-notificacion.module#AnularNotificacionPageModule' },
  { path: 'consulta-totales', loadChildren: './consulta-totales/consulta-totales.module#ConsultaTotalesPageModule' },
  { path: 'consulta-totales-valorizada', loadChildren: './consulta-totales-valorizada/consulta-totales-valorizada.module#ConsultaTotalesValorizadaPageModule' },
  { path: 'legebilidad-status', loadChildren: './legebilidad-status/legebilidad-status.module#LegebilidadStatusPageModule' },
  { path: 'solicitar-practica-status', loadChildren: './solicitar-practica-status/solicitar-practica-status.module#SolicitarPracticaStatusPageModule' },
  { path: 'solicitar-practica-validar', loadChildren: './solicitar-practica-validar/solicitar-practica-validar.module#SolicitarPracticaValidarPageModule' },
  { path: 'consulta-totales-result', loadChildren: './consulta-totales-result/consulta-totales-result.module#ConsultaTotalesResultPageModule' },
  { path: 'consulta-totales-valorizada-result', loadChildren: './consulta-totales-valorizada-result/consulta-totales-valorizada-result.module#ConsultaTotalesValorizadaResultPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
